body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    background-color: steelblue;
    min-height: 100vh;
    /* background-color: black;
    color: darkgray; */
}

.card {
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    position: absolute;
    top: 5em;
    left: 2em;
    right: 2em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 25px;
    
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap-reverse; */
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.card > .field-set {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.card > .field-set > .field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.card > .field-set > .field > p {
    min-width: 10em;
    align-content: right;
    text-align: right;
    flex-grow: 0;
}

.card > .field-set > .field > input {
    flex-grow: 1;
    min-width: 20em;
    align-content: right;
}

.card > .field-set > .field > .editable-price-field {
    flex-grow: 1;
    min-width: 20em;
    align-content: right;
}

.card > .field-set > .field > .select {
    flex-grow: 1;
    min-width: 20em;
    align-content: right;
}

.card > .field-set > .field > .select > div {
    padding-top: 2px;
    padding-bottom: 2px;
}

.card > .field-set > .field > textarea {
    flex-grow: 2;
    min-width: 20em;
    align-content: right;
    height: 6em;
}


.catImg {
    min-width: 100px;
    flex-basis: 40%;
}

.checkbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25em;
}

.checkboxDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.deleteButton{
    background-color: red;
    padding: 0.25em;
}

.field.buttons {
    padding-top: 2em;
}

#debug {
    position: absolute;
    top: 1px;
    right: 0px;
    display: flex;
    flex-direction: row;
    padding-top: 1em;
    padding-right: 1em;
}

.header-toolbar {
    padding-left: 8em !important;
}

.inventory {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
}

.inventory .count-input {
    width: 5em;
    min-width: 5em;
    display: inline-flex;
  }
  
  .inventory .count-input svg {
    width: 0.5em;
    padding-top: 0em;
    padding-bottom: 0em;
  }
  
  
  .inventory .count-input input {
    font-size: 0.8em;
    height: 1em;
    padding: 1em;
  }
  
  .inventory .count-input .count-input-buttons {
    height: 1em;
  }
  .inventory .count-input .count-input-buttons button {
    padding-top: 0em;
    padding-bottom: 0em;
  }
  
  