.price {
    font-weight: bold;
}

.dollars {
    display: inline;
}

.cents {
    display: inline;
    vertical-align: top;
    font-size: 0.7em;
}
